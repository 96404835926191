import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from "../components/Common/PageBanner"
import Footer from "../components/App/Footer"
import ServiceDetailsContent from "../components/ServiceDetails/ServiceDetailsContent"
import RelatedServices from "../components/ServiceDetails/RelatedServices"
import MobileAppImage from "../assets/images/services/MobileApp.png"

const MobileAppDevelopment = () => {
  return (
    <Layout>
      <Navbar />
      <PageBanner
        pageTitle="Mobile App Development"
        homePageText="Home"
        homePageUrl="/"
        activePageText="Mobile App Development"
      />
      <ServiceDetailsContent
        aboutService="Our Mobile App Developers are adopting modern, user based methodologies, and technologies for building both IOS AND Android mobile apps. ​"
        technologies={[
          "React Native",
          "Expo",
          "JavaScript",
          "Android Studio",
          "Redux",
          "Typescript",
          "Firebase",
          "Nodejs",
          "MongoDB",
        ]}
        detailImage={MobileAppImage}
      />
      {/* <RelatedServices /> */}
      <Footer />
    </Layout>
  )
}

export default MobileAppDevelopment
